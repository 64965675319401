<template>
    <div>
      <CRow>
        <CCol col="12" class="mb-2" v-if="isadmin">
          <form @submit.prevent="submit">
            <input type="file" @change="handleFileUpload( $event )">
            <CButton
              @click="send()"
              color="primary"
            >
              <CSpinner v-if="loading" variant="grow" />
              Cargar
            </CButton>
          </form>
          <a :href="this.backend+'/invoices/licences.xlsx'">
            Descargar formato
          </a>
        </CCol>
      </CRow>
      <CRow> 
        <CCol col="12" class="mb-2">
          <CInput placeholder="Ingrese el texto a buscar" v-model="search">
            <template #prepend-content
              ><font-awesome-icon icon="search"
            /></template>
          </CInput>
        </CCol>
        <CCol sm="12" md="3">
          <CInput type="date" label="Fecha desde" v-model="start" />
        </CCol>
        <CCol sm="12" md="3">
          <CInput type="date" label="Fecha hasta" v-model="end" />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12" md="3">
            <CButton
              @click="getItems()"
              color="primary"
            >
              <CSpinner v-if="loading" variant="grow" />
              Buscar
            </CButton>
        </CCol>
    
        <CCol col="12">
          <CCard>
            <CCardHeader> Licencias Cargadas </CCardHeader>
    
            <CCardBody>
              <div class="text-center">
                <CSpinner v-if="loading" variant="grow" size="lg" />
              </div>
    
              <CDataTable
                v-if="!loading"
                hover
                striped
                sorter
                :items="items"
                :fields="fields"
              />
    
              <CPagination
                v-if="!loading"
                :activePage.sync="page"
                :pages="lastPage"
                size="sm"
                align="center"
                @update:activePage="pageChange"
              />
            </CCardBody>
          </CCard>
        </CCol>
        <CButton class="float-right mr-2" color="success" @click="exportData()">
          Exportar
        </CButton>
        <CToaster :autohide="3000">
          <template>
            <CToast
              v-for="(item, index) in toasts"
              :key="`${item.title}-${index}`"
              :show="true"
              :color="item.color"
              :header="item.title"
            >
              {{ item.message }}
            </CToast>
          </template>
        </CToaster>
      </CRow>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  import Resource from "@/api/resource";
  import ReportResource from "@/api/report";
  
  const resource = new Resource("licenselink");
  const reportResource = new ReportResource();
  
  export default {
    name: "Stock",
    data() {
      return {
        items: [],
        loading: false,
        fields: [
          {
            key: "license",
            label: "Licencias",
          },
          {
            key: "hash",
            label: "Hash",
          },
          {
            key: "created_at",
            label: "Fecha creación",
          },
        ],
        start: null,
        end: null,
        search: null,
        page: 1,
        perPage: 10,
        lastPage: 1,
        file: null,
        toasts: [],
        isadmin: false,
        isvendor: false,
        issubdistributor: false,
        backend: "",
      };
    },
    mounted() {
      this.backend = process.env.VUE_APP_BACKEND_URL;
      this.isadmin = this.$store.state.user.role == "Administrador";
      this.isvendor = this.$store.state.user.role == "Vendedor BSmart";
      this.issubdistributor = this.$store.state.user.role == "Subdistribuidor";
      this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
      this.getItems();
    },
  
    methods: {
      handleFileUpload( event ){
        this.file = event.target.files[0];
      },
      async getItems() {
        this.loading = true;
        try {
          const response = await resource.list({
            page: this.page,
            perPage: this.perPage,
          });
          if (response.status == 200) {
            this.items = response.data.data;
            this.lastPage = response.data.last_page;
          } else {
            console.log(response);
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.loading = false;
        }
      },
  
      async pageChange(val) {
        this.$router.push({ query: { page: val } });
        await this.getItems();
        this.page = val;
      },
      async send() {
        this.loading = true;
        let formData = new FormData();
        formData.append('file', this.file);
        await axios.post(this.backend+'/api/licenselink',
          formData,
          {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.user.token,
              'Content-Type': 'multipart/form-data',
              'Accept':'application/json',
            },
          }
        ).then((data) => {
          this.loading = false;
          this.getItems();
          this.toasts.push({
              title: "Confirmación",
              color: "success",
              message: "Activado con exito",
            });
        })
        .catch((error) => {
          this.loading = false;
          var errors = error.response.data.message;
          this.toasts.push({
              title: "Error",
              color: "danger",
              message: errors,
            });
        });
      },
      async exportData() {
        await reportResource.licences().then((response) => {
          const blob = new Blob([response.data]);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "licencias.xlsx";
          link.click();
          URL.revokeObjectURL(link.href);
        });
      },
    },
  };
  </script>
  